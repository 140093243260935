<template>
  <div class="ms-otp-one-col">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-2fa-icon-wrap"><span class="ms-icon2fa"></span></div>
    <div class="ms-otp-form-title">{{ $t('auth.twoFactorAuthDevice.title') }}</div>
    <div class="ms-otp-caption">{{ $t('auth.twoFactorAuthDevice.description') }}</div>

    <div class="ms-link-navigation">
      <input type="button" class="ms-otp-btn ms-otp-btn-outline" @click="pushToBackStep" :value="$t('default.back.title')"/>
      <div class="ms-otp-info-txt">{{ $t('auth.twoFactorAuthDevice.sendSmsInsteadText') }}</div>
      <a class="ms-forward-link" href="javascript:" @click='pushTo2FACodeStep'>
        {{ $t('auth.twoFactorAuthDevice.sendSmsInsteadLink') }}
      </a>
    </div>
  </div>
</template>

<script>
import UrlManager from '@/util/UrlManager'
import {signinFlowApi} from "@/api/SigninFlowApi";
import {mixinLoader} from "@/mixins/mixin-loader";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";
import Stomp from "webstomp-client";
import {toastNotification} from "@/mixins/toast-notification";
import {SigninProcessHeader} from "@/constants/SigninProcessHeader";

export default {
  name: 'Auth2FADevice',
  mixins: [
    mixinLoader,
    toastNotification,
  ],
  data () {
    return {
      errors: [],
      approvalRequestId: null,
    }
  },
  created () {
    this.showSpinner()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.AUTH_2FA_DEVICE})
      .then((response) => {
        this.send2FADevice()
      })
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.UPDATE_AGREEMENT,
        SigninProcessStep.UPDATE_PASSWORD,
        SigninProcessStep.LOGIN,
      ]
    )
  },
  computed: {
    currentLocale () {
      return this.$store.state.locale
    },
  },
  methods: {
    send2FADevice () {
      this.showSpinner()
      signinFlowApi.send2FADevice()
        .then((response) => {
          this.approvalRequestId = response.data.approvalRequestId
          this.connectToWebSocket()
        })
        .catch((error) => {
          this.hideSpinner()
          this.showWebsocketConnectionError()
        })
        .finally(() => {
          this.hideSpinner()
        })
    },
    connectToWebSocket () {
      this.showSpinner()
      this.stompClient = Stomp.client(process.env.VUE_APP_AUTH_WEB_SOCKET_URL)
      this.stompClient.connect(
        {},
        (frame) => {
          this.stompClient.subscribe('/topic/2fa/device/' + this.approvalRequestId, (message) => {
            const data = JSON.parse(message.body)
            this.enforceNextStep(data)
            this.stompClient.disconnect()
          })
          this.hideSpinner()
        },
        (error) => {
          this.hideSpinner()
          this.showWebsocketConnectionError()
        },
      )
    },
    showWebsocketConnectionError () {
      this.showErrorNotification(this.$t('auth.twoFactorAuthDevice.connectionError'))
    },
    enforceNextStep (data) {
      const params = {
        currentStep: SigninProcessStep.AUTH_2FA_DEVICE,
      }
      const headers = {
        [SigninProcessHeader.SIGNIN_PROCESS_TOKEN]: data.signinProcessToken,
      }
      signinFlowApi.enforceStep(params, headers)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.$router.push({ path: '/' })
        })
    },
    pushToBackStep() {
      this.showSpinner()
      this.$router.push({ path: '/' })
    },
    pushTo2FACodeStep() {
      this.showSpinner()
      this.stompClient.disconnect()
      this.$router.push({ path: '/signin/2fa/code' })
    },
    localizeUrl (path) {
      return UrlManager.localizeUrl('', path)
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/helpers/all-helpers';
.ms-otp-btn{
  margin: 0 0 24px;
}
</style>
