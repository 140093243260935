<template>
  <div class="ms-language-selector">
    <Select2 v-model="currentLocale" :options="locales" @change="switchLocale"/>
  </div>
</template>

<script>
import Select2 from 'v-select2-component'
import { ALL_LOCALES } from '../../i18n'

export default {
  name: 'LocaleSelector',
  components: {
    Select2
  },
  data () {
    return {
      locales: ALL_LOCALES,
      currentLocale: this.$store.state.locale
    }
  },
  methods: {
    switchLocale (localeVal) {
      this.$store.commit('setLocale', localeVal)
    }
  }
}
</script>
<style lang="scss">
.ms-language-selector {
  margin: 0;
  @include sm-up {
    margin: 0 26px;
  }
}
.select2-container {
  width: 55px!important;
}
.select2-search--dropdown {
  display: none!important;
}
.select2-selection--single {
  border: 0!important;
  text-transform: uppercase;
  outline: unset;
}
.select2-selection__rendered {
  font-weight: 600;
  font-family: $inter;
  font-size: 14px;
  line-height: 1;
  color: $light-gray!important;
  @include md-up {
    font-size: 16px;
  }
}
.select2-dropdown {
  border: 0!important;
  @include box-shadow(0 1px 8px rgba(0,0, 0, .12));
}
.select2-container--open .select2-dropdown {
  top: 4px;
  left: -16px!important;
}
.select2-container--open .select2-dropdown--below {
  min-width: 70px;
  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $white-bg;
  }
}
.select2-container--default .select2-results__option {
  background-color: $white-bg!important;
  font-weight: 400;
  font-family: $inter;
  font-size: 14px;
  line-height: 1;
  color: $black-text;
  text-transform: uppercase;
  text-align: center;
}
.select2-results__option {
  padding: 13px 0!important;
  border-bottom: 1px solid rgba(194, 200, 204, 0.2);
  &:last-child {
    border-bottom: 0;
  }
}
.select2-results__option--highlighted {
  background-color: $white-bg!important;
  color: $turquoise-text!important;
}
.select2-selection__arrow b {
  display: none!important;
}
.select2-container--default {
  &.select2-container--open {
    .select2-selection__arrow {
      &:before {
        @include transform(rotate(225deg));
        top: 10px;
      }
    }
  }
  .select2-selection__arrow {
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 5px;
      height: 5px;
      border-right: 2px solid $light-gray;
      border-bottom: 2px solid $light-gray;
      @include transform(rotate(45deg));
      @include transition(.3s);
    }
  }
}
</style>
