<template>
  <div class="ms-otp-one-col">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-otp-form-title">{{ $t('auth.enableTwoFactorAuthPhone.title') }}</div>
    <div class="ms-otp-caption">{{ $t('auth.enableTwoFactorAuthPhone.description') }}</div>

    <form @submit.prevent="pushToNextStep" id="enable2FAForm" autocomplete="off">
      <div class="ms-otp-form-row">
        <div v-bind:class="[phoneNumberFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <input class="ms-otp-input" type="tel" name="phoneNumber" ref="phoneNumberInput"
                 :placeholder="$t('auth.enableTwoFactorAuthPhone.phoneNumber')" v-model="phoneNumber"
                 @focus="phoneNumberFieldErrorMessage = null"
                 @input="filterPhoneInput" @keydown="handlePhoneKeydown" @paste="handlePhonePaste"/>
          <span v-if="phoneNumberFieldErrorMessage" class="ms-otp-error-text">{{ phoneNumberFieldErrorMessage }}</span>
        </div>
      </div>

      <div class="ms-otp-btn-wrap">
        <input type="button" class="ms-otp-btn ms-otp-btn-outline" @click="cancelEnable2FA"
               :value="$t('default.cancel.title')"/>
        <input type="submit" class="ms-otp-btn" id="submit" :value="$t('default.next.title')">
      </div>
    </form>
  </div>
</template>

<script>
import UrlManager from '@/util/UrlManager'
import {signinFlowApi} from "@/api/SigninFlowApi";
import OtpUtils from "@/util/OtpUtils";
import {mixinLoader} from "@/mixins/mixin-loader";
import OtpInput from "@/components/helpers/otp/OtpInput.vue";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";

export default {
  name: 'Enable2FAPhone',
  mixins: [mixinLoader],
  data () {
    return {
      errors: [],
      phoneNumber: '+41',
    }
  },
  created () {
    this.showSpinner()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.ENABLE_2FA})
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.LOGIN,
      ]
    )
  },
  computed: {
    currentLocale () {
      return this.$store.state.locale
    },
    phoneNumberFieldErrorMessage: {
      get () {
        return this.getFieldError('phoneNumber')
      },
      set () {
        const locationError = this.getLocationError('phoneNumber')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
  },
  methods: {
    localizeUrl (path) {
      return UrlManager.localizeUrl('', path)
    },
    pushToNextStep () {
      this.showSpinner()
      const params = {
        phoneNumber: this.phoneNumber
      }
      signinFlowApi.sendEnable2FACode(params)
        .then((response) => {
          this.$store.commit('setEnable2FAPhoneNumber', this.phoneNumber)
          this.$router.push({ path: this.localizeUrl('/signin/2fa/enable/code') })
        })
        .catch((error) => {
          this.errors = error.response.data.errors
        })
        .finally(() => this.hideSpinner())
    },
    cancelEnable2FA () {
      this.showSpinner()
      signinFlowApi.cancel2FA()
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.hideSpinner()
        })
    },
    filterPhoneInput() {
      this.phoneNumber = this.phoneNumber.replace(/[^\d+]/g, '').replace(/(^\+)|(\+)/g, (match, p1) => p1 ? '+' : '');
    },
    handlePhoneKeydown(event) {
      // Allow key combinations with Ctrl or Shift (like Ctrl+C, Ctrl+V, etc.)
      if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) {
        return;
      }

      // Allow only digit and plus characters
      const allowedKeys = ['Tab', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', '+'];
      if (!event.key.match(/\d/) && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    handlePhonePaste(event) {
      event.preventDefault();
      const text = (event.clipboardData || window.clipboardData).getData('text');
      // Filter the pasted text and combine it with the current input
      this.phoneNumber = text
        .replace(/[^\d+]/g, '')
        .replace(/(^\+)|(\+)/g, (match, p1) => p1 ? '+' : '');
    },
    getLocationError (field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError (field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/helpers/all-helpers';
</style>
