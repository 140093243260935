<template>
  <div class="ms-otp-one-col">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-otp-registration-title-wrapper">
      <div class="ms-otp-form-title">{{ $t('auth.updatePassword.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.updatePassword.description') }}</div>
    </div>
    <form @submit.prevent="submitUpdatePassword" id="updatePasswordStepForm">
      <div class="ms-otp-form-content-wrapper">
        <div v-bind:class="[passwordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <div class="ms-otp-input-password-wrap">
            <input v-if="showPassword" class="ms-otp-input" type="text" name="password"
                   :placeholder="$t('auth.patientRegistration.placeholder.password')"
                   v-model="password" @focus="passwordFieldErrorMessage = null" autocomplete="new-password">
            <input v-else type="password" class="ms-otp-input" :placeholder="$t('auth.login.password.placeholder')"
                   v-model="password" @focus="passwordFieldErrorMessage = null" autocomplete="new-password">
            <div :class="{ 'ms-eye': showPassword, 'ms-eye-slash': !showPassword }" class="ms-show-password-btn"
                 @click="togglePasswordShow">
              <icon-eye-slashed/>
              <icon-eye/>
            </div>
          </div>
          <span v-if="passwordFieldErrorMessage" class="ms-otp-error-text">{{ passwordFieldErrorMessage }}</span>
        </div>
        <div v-bind:class="[confirmPasswordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <div class="ms-otp-input-password-wrap">
            <input v-if="showConfirmPassword" class="ms-otp-input" type="text" name="password"
                   :placeholder="$t('auth.patientRegistration.placeholder.confirmPassword')"
                   v-model="confirmPassword" @focus="confirmPasswordFieldErrorMessage = null"
                   autocomplete="new-password">
            <input v-else type="password" class="ms-otp-input"
                   :placeholder="$t('auth.login.confirmPassword.placeholder')"
                   v-model="confirmPassword" @focus="confirmPasswordFieldErrorMessage = null"
                   autocomplete="new-password">
            <div :class="{ 'ms-eye': showConfirmPassword, 'ms-eye-slash': !showConfirmPassword }"
                 class="ms-show-password-btn"
                 @click="toggleConfirmPasswordShow">
              <icon-eye-slashed/>
              <icon-eye/>
            </div>
          </div>
          <span v-if="confirmPasswordFieldErrorMessage"
                class="ms-otp-error-text">{{ confirmPasswordFieldErrorMessage }}</span>
        </div>
      </div>
      <div class="ms-otp-btn-wrap">
        <input type="submit" class="ms-otp-btn" :value="$t('auth.updatePassword.button')"/>
      </div>
      <div class="ms-link-navigation">
        <router-link :to="{ path: localizeUrl('', '/') }" class="ms-back-link">{{
            $t('default.back.title')
          }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import IconEyeSlashed from '@/components/icons/IconEyeSlashed.vue'
import IconEye from '@/components/icons/IconEye.vue'
import UrlManager from '@/util/UrlManager'
import {mixinLoader} from "@/mixins/mixin-loader";
import {signinFlowApi} from "@/api/SigninFlowApi";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";

export default {
  name: 'UpdatePassword',
  mixins: [mixinLoader],
  components: {
    IconEye,
    IconEyeSlashed
  },
  data () {
    return {
      errors: [],
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
    }
  },
  computed: {
    solutionHost() {
      return process.env.VUE_APP_SOLUTION_HOST
    },
    emailFieldErrorMessage: {
      get() {
        return this.getFieldError('email')
      },
      set() {
        const locationError = this.getLocationError('email')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    passwordFieldErrorMessage: {
      get() {
        return this.getFieldError('password')
      },
      set() {
        const locationError = this.getLocationError('password')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    confirmPasswordFieldErrorMessage: {
      get() {
        return this.getFieldError('confirmPassword')
      },
      set() {
        const locationError = this.getLocationError('confirmPassword')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    currentLocale () {
      return this.$store.state.locale
    }
  },
  created () {
    this.showSpinner()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.UPDATE_PASSWORD})
      .then((response) => {
        this.email = response.data.email
      })
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.UPDATE_AGREEMENT,
        SigninProcessStep.LOGIN,
      ]
    )
  },
  methods: {
    submitUpdatePassword () {
      const params = {
        password: this.password,
        confirmPassword: this.confirmPassword,
      }

      this.showSpinner()
      signinFlowApi.updatePassword(params)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.hideSpinner()
        })
    },
    getLocationError(field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError(field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el, index, array) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    localizeUrl(host, path) {
      return UrlManager.localizeUrl(host, path)
    },
    togglePasswordShow() {
      this.showPassword = !this.showPassword
    },
    toggleConfirmPasswordShow() {
      this.showConfirmPassword = !this.showConfirmPassword
    },
    showSpinner() {
      this.$emit('loading-increment')
    },
    hideSpinner() {
      this.$emit('loading-decrement')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/helpers/all-helpers';


.ms-otp-caption {
  margin: 0 auto 34px;
  @include md-up {
    max-width: 75%;
  }
}

.ms-otp-form-content-wrapper {
  @include display-flex;
  @include flex-direction-column;
}

.ms-otp-form-row {
  margin-bottom: 16px;
}

.ms-otp-form-content-title {
  font-family: $montserrat;
  font-weight: 600;
  font-size: 14px;
  color: $black-text;
  margin-bottom: 21px;
}

.ms-otp-register-agree-text::v-deep {
  max-height: 125px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #FFFFFF;
  border: 1px solid #00D0C8;
  border-radius: 6px;
  padding: 16px 22px 22px;
  font-family: $montserrat;
  font-weight: 500;
  font-size: 12px;
  line-height: 165%;
  color: $dark-gray-text;
  margin: 21px 0;

  b {
    font-size: 14px;
  }

  a {
    color: $turquoise-bg;
  }
  .msr-table-wrap{
    max-width: 100%;
    overflow: auto;
  }
  .msr-table-flex-table {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: 100%;
    max-width: 100%;
    min-width: 600px;
    margin: 20px auto;
  }
  .msr-table-flex-row {
    display: flex;
    border-top: 1px solid;
    &:first-child{
      border-top: none;
    }
  }
  .msr-table-header {
    font-family: $montserrat;
    font-weight: 500;
  }
  .msr-table-flex-cell {
    padding: 10px;
    border-right: 1px solid;
  }
  .msr-table-flex-cell:first-child {
    flex: 1;
    width: 30%;
    @media (max-width: 991px) {
      word-break: break-all;
    }
  }
  .msr-table-flex-cell:nth-child(2){
    width: 30%;
  }
  .msr-table-flex-cell:nth-child(3) {
    width: 40%;
    word-break: break-all;
  }
  .msr-table-flex-cell:last-child {
    border-right: none;
  }
  .ms-otp-register-table {
    max-width: 100%;
    overflow: auto;
  }
}

.ms-otp-checkbox-wrap {
  position: relative;
}

.ms-otp-tooltip-pw {
  position: absolute;
  right: -30px;

  &.active {
    .ms-tooltip-text {
      display: block;
      opacity: 1;
      visibility: visible;
      @include lg-up {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  @include lg-up {
    &:hover {
      .ms-tooltip-text {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .ms-tooltip-text {
    position: absolute;
    top: 30px;
    left: unset;
    right: -44px;
    display: none;
    width: 300px;
    background-color: #FFFFFF;
    padding: 4px 6px;
    font-family: $montserrat;
    font-weight: 400;
    font-size: 10px;
    opacity: 0;
    visibility: hidden;
    @include box-shadow(0px 2px 10px rgba(0, 0, 0, 0.2));
    @include border-radius(6px);
    @include transition(.3s);
    z-index: 2;
    @include ltm-up {
      left: -6px;
    }

    &:before {
      content: " ";
      position: absolute;
      top: -12px;
      left: unset;
      right: 48px;
      border-top: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #FFFFFF;
      @include ltm-up {
        left: 10px;
      }
    }
  }
}
</style>
