<template>
  <div class="ms-otp-one-col">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-otp-form-title">{{ $t('auth.twoFactorAuthCode.title') }}</div>
    <div class="ms-otp-caption" v-html="$t('auth.twoFactorAuthCode.description', [this.phoneNumber])"></div>

    <form ref="form" @submit.prevent="submit2FACode" id="2faCodeStepForm" autocomplete="off">
      <div class="ms-otp-form-row">
        <div class="ms-otp-input-code-wrap">
          <otp-input
            ref="otpInput"
            :channel="otpChannel"
            :input-classes="'ms-otp-input' + (otpFieldErrorMessage ? ' ms-otp-error-field' : '')"
            @on-change="handleOtpChange"
            @on-complete="handleOtpComplete"
          />
        </div>
        <div class="ms-otp-error-field">
          <span v-if="otpFieldErrorMessage" class="ms-otp-error-text">{{ otpFieldErrorMessage }}</span>
        </div>
      </div>
      <div class="ms-otp-btn-wrap">
        <input type="submit" class="ms-otp-btn" id="submit" :value="$t('default.next.title')"/>
      </div>
    </form>

    <div class="ms-link-navigation">
      <router-link :to="{ path: localizeUrl('/') }" class="ms-back-link">
        {{ $t('default.back.title') }}
      </router-link>
      <a class="ms-forward-link" href="javascript:" @click='send2FACode'>
        {{ resendCodeText }}
      </a>
    </div>
  </div>
</template>

<script>
import UrlManager from '@/util/UrlManager'
import {signinFlowApi} from "@/api/SigninFlowApi";
import OtpUtils from "@/util/OtpUtils";
import {mixinLoader} from "@/mixins/mixin-loader";
import OtpInput from "@/components/helpers/otp/OtpInput.vue";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";
import {OtpChannel} from "@/constants/OtpChannel";

export default {
  name: 'Auth2FACode',
  components: {
    'otp-input': OtpInput
  },
  mixins: [mixinLoader],
  data () {
    return {
      errors: [],
      phoneNumber: '',
      code: '',
      secondsBeforeResend: 0,
    }
  },
  created () {
    this.showSpinner()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.AUTH_2FA_CODE})
      .then((response) => {
        this.phoneNumber = response.data.phoneNumber
        this.send2FACode()
      })
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.UPDATE_AGREEMENT,
        SigninProcessStep.UPDATE_PASSWORD,
        SigninProcessStep.LOGIN,
      ]
    )
  },
  computed: {
    otpChannel () {
      return OtpChannel.SMS
    },
    currentLocale () {
      return this.$store.state.locale
    },
    resendCodeText () {
      return this.secondsBeforeResend > 0
        ? this.$t('auth.otp.singInOtpPage.sendAgain.timer.title', [this.secondsBeforeResend])
        : this.$t('auth.otp.singInOtpPage.sendAgain.ready.title')
    },
    otpFieldErrorMessage: {
      get () {
        return this.getFieldError('otp')
      },
      set () {
        const locationError = this.getLocationError('otp')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    }
  },
  methods: {
    handleOtpChange (otp) {
      this.code = otp
      this.otpFieldErrorMessage = ''
    },
    handleOtpComplete (otp) {
      this.code = otp
      this.otpFieldErrorMessage = ''
      this.$refs.form.requestSubmit()
    },
    localizeUrl (path) {
      return UrlManager.localizeUrl('', path)
    },
    send2FACode () {
      if (this.secondsBeforeResend > 0) {
        return
      }

      this.showSpinner()
      signinFlowApi.send2FACode()
        .then((response) => {
          this.secondsBeforeResend = OtpUtils.getResendLeftSeconds(response.data)
          this.$refs.otpInput.clearInput();
        })
        .catch((error) => {
          this.errors = error.response.data.errors
        })
        .finally(() => this.hideSpinner())
    },
    submit2FACode () {
      this.showSpinner()
      signinFlowApi.authenticateBy2FACode({otp: this.code})
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.hideSpinner()
        })
    },
    getLocationError (field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError (field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  },
  watch: {
    secondsBeforeResend: {
      handler (seconds) {
        if (seconds > 0) {
          setTimeout(() => this.secondsBeforeResend--, 1000)
        }
      },
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/helpers/all-helpers';
</style>
