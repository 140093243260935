<template>
  <div class="ms-otp-one-col">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-otp-form-title">{{ $t('auth.enableTwoFactorAuthSuccess.title') }}</div>
    <div class="ms-otp-caption">{{ $t('auth.enableTwoFactorAuthSuccess.description') }}</div>

    <form @submit.prevent="enable2FASuccess" id="enable2FASuccessForm" autocomplete="off">
      <div class="ms-otp-btn-wrap">
        <input type="submit" class="ms-otp-btn" id="submit" :value="$t('default.next.title')">
      </div>
    </form>
  </div>
</template>

<script>
import {signinFlowApi} from "@/api/SigninFlowApi";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";
import {mixinLoader} from "@/mixins/mixin-loader";

export default {
  name: 'Enable2FASuccess',
  mixins: [mixinLoader],
  created () {
    this.showSpinner()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.ENABLE_2FA_SUCCESS})
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.LOGIN,
      ]
    )
  },
  computed: {},
  methods: {
    enable2FASuccess () {
      this.showSpinner()
      signinFlowApi.enable2FASuccess()
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.hideSpinner()
        })
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/helpers/all-helpers';

.ms-otp-qr {
  background: #fff;
  border: 1px solid #a0e8b8;
  height: 175px;
  padding: 11px;
  width: 175px;
  border-radius: 12px;
  margin: 0 auto 25px;

  img {
    width: 100%;
  }
}
.ms-app-links {
  @include display-flex;
  @include flex-direction-column;
  @include align-items-center;
  @include justify-content-center;
  @include md-up {
    @include align-items-flex-start;
    @include flex-direction-row;
  }
  svg{
    height: 30px;
    width: 22px;
  }
}

.ms-app-link {
  @include display-flex;
  @include align-items-center;
  position: relative;
  padding-left: 20px;
  width: 200px;
  height: 47px;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
  @include border-radius(12px);
  cursor: pointer;
  @include transition(0.5s);

  &:first-child {
    margin-bottom: 28px;
  }

  @include sm-up {
    width: 163px;
    height: 39px;
    @include border-radius(8px);
    &:first-child {
      margin-bottom: 13px;
    }
  }
  @include md-up {
    width: 192px;
    height: 55px;
    padding-left: 20px;
    @include border-radius(12px);
    &:first-child {
      margin-right: 13px;
    }
    &:hover {
      @include box-shadow(4px 10px 45px rgba(0, 0, 0, 0.05));
    }
  }

  .ms-app-link-text {
    @include display-flex;
    @include flex-direction-column;
    cursor: pointer;
    margin-left: 17px;
    @include sm-up {
      margin-left: 12px;
    }
    @include md-up {
      margin-left: 20px;
    }
  }

  .ms-app-caption {
    font-family: $inter;
    font-size: 10px;
    line-height: 110%;
  }

  .ms-app-name {
    font-family: $inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }
}
</style>
