import Vue from 'vue'
import Router from 'vue-router'
import AllContent from './components/AllContent.vue'
import { ALL_LOCALES } from './i18n'
import { store } from './vuex'
import OidcAuth from '@/components/pages/signin/oidc/OidcAuth'
import OidcAuthError from '@/components/pages/signin/oidc/OidcAuthError'
import SmnAuth from '@/components/pages/signin/oidc/partner/SmnAuth'
import MedicosearchAuth from '@/components/pages/signin/oidc/partner/MedicosearchAuth'
import { redirectParams } from '@/main'
import LesPsyAuth from "./components/pages/signin/oidc/partner/LesPsyAuth"
import NotFoundPage from '@/views/error/NotFoundPage'
import Registration from "@/components/pages/signin/Registration.vue";
import UpdateAgreement from "@/components/pages/signin/UpdateAgreement.vue";
import CMPFribourgAuth from '@/components/pages/signin/oidc/partner/CMPFribourgAuth'
import Cookies from "@/util/Cookies";
import UserDataUtils from "@/util/UserDataUtils";
import CREPsyAuth from "./components/pages/signin/oidc/partner/CREPsyAuth.vue";
import UpdatePassword from "@/components/pages/signin/UpdatePassword.vue";
import AuthOtp from "@/components/pages/signin/AuthOtp.vue";
import AuthPassword from "@/components/pages/signin/AuthPassword.vue";
import RequestSignin from "@/components/pages/signin/RequestSignin.vue";
import Auth2FADevice from "@/components/pages/signin/2fa/Auth2FADevice.vue";
import Auth2FACode from "@/components/pages/signin/2fa/Auth2FACode.vue";
import Enable2FAQR from "@/components/pages/signin/2fa/Enable2FAQR.vue";
import Enable2FACode from "@/components/pages/signin/2fa/Enable2FACode.vue";
import Enable2FAPhone from "@/components/pages/signin/2fa/Enable2FAPhone.vue";
import Enable2FASuccess from "@/components/pages/signin/2fa/Enable2FASuccess.vue";
import VitaPsyAuth from "@/components/pages/signin/oidc/partner/VitaPsyAuth.vue";
import CcbCabAuth from "@/components/pages/signin/oidc/partner/CcbCabAuth.vue";

Vue.use(Router)

let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')

if (ALL_LOCALES.indexOf(locale) === -1) {
  locale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
}
store.commit('setLocale', locale)

const urls = [
  {
    path: 'oidc',
    component: OidcAuth
  },
  {
    path: 'auth/ms',
    component: MedicosearchAuth,
    meta: { hideFooter: true, customHeaderClass: 'sso-header-medicosearch' }
  },
  {
    path: 'auth/smn',
    component: SmnAuth,
    meta: { hideFooter: true, customHeaderClass: 'sso-header-smn' }
  },
  {
    path: 'auth/lespsyreunis',
    component: LesPsyAuth,
    meta: { hideFooter: true, customHeaderClass: 'sso-header-lespsy' }
  },
  {
    path: 'auth/cmpfribourg',
    component: CMPFribourgAuth,
    meta: { hideFooter: true, customHeaderClass: 'sso-header-fribourg' }
  },
  {
    path: 'auth/crepsy',
    component: CREPsyAuth,
    meta: { hideFooter: true, customHeaderClass: 'sso-header-crepsy' }
  },
  {
    path: 'auth/vitapsy',
    component: VitaPsyAuth,
    meta: { hideFooter: true, customHeaderClass: 'sso-header-vitapsy' }
  },
  {
    path: 'auth/ccbcab',
    component: CcbCabAuth,
    meta: { hideFooter: true, customHeaderClass: 'sso-header-ccbcab' }
  },
  {
    path: 'oidc-login-error',
    component: OidcAuthError
  },
  // signin flow
  {
    path: 'signin/otp',
    component: AuthOtp
  },
  {
    path: 'signin/password',
    component: AuthPassword
  },
  {
    path: 'signin/2fa/code',
    component: Auth2FACode
  },
  {
    path: 'signin/2fa/device',
    component: Auth2FADevice
  },
  {
    path: 'signin/registration',
    component: Registration
  },
  {
    path: 'signin/agreement/update',
    component: UpdateAgreement
  },
  {
    path: 'signin/password/update',
    component: UpdatePassword
  },
  {
    path: 'signin/2fa/enable/phone',
    component: Enable2FAPhone
  },
  {
    path: 'signin/2fa/enable/code',
    component: Enable2FACode
  },
  {
    path: 'signin/2fa/enable/success',
    component: Enable2FASuccess
  },
  {
    path: 'signin/2fa/enable/qr',
    component: Enable2FAQR
  },
]

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '/',
      component: RequestSignin
    },
    {
      path: '/(fr|en|it)/',
      component: RequestSignin
    },
    {
      path: '/(fr|en|it)',
      component: AllContent,
      children: urls
    },
    {
      path: '/',
      component: AllContent,
      children: urls
    },
    {
      path: '/404',
      name: '404',
      component: NotFoundPage
    },
    {
      path: '*',
      component: NotFoundPage
    }
  ]
})

router.beforeEach((to, from, next) => {
  let needToPush = false
  if (from.query && to.query) {
    if (from.query.displayType === 'embed' && to.query.displayType !== 'embed') {
      to.query.displayType = 'embed'
      needToPush = true
    }

    if (from.query.autofillType && to.query.autofillType !== from.query.autofillType) {
      to.query.autofillType = from.query.autofillType
      needToPush = true
    }
  }

  if (to.query) {
    if (to.query.apiKey) {
      UserDataUtils.setApiKey(to.query.apiKey)
      to.query.apiKey = undefined
      needToPush = true
    }

    if (to.query.displayType) {
      UserDataUtils.setDisplayType(to.query.displayType)
    } else {
      UserDataUtils.deleteDisplayType()
    }

    if (to.query.serviceName || to.query.backUrl) {
      redirectParams.setRedirectData(to.query.serviceName, to.query.backUrl)
      to.query.serviceName = undefined
      to.query.backUrl = undefined
      needToPush = true
    }
  }

  if (needToPush) {
    router.push(to)
    return
  }

  let urlLocale = to.path.replace(/^\/([^\/]+).*/i, '$1')

  if (ALL_LOCALES.indexOf(urlLocale) === -1) {
    urlLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
  }

  updatePreferredLanguage()
  if (store.state.locale !== urlLocale) {
    const newUrl = addLocalePrefixForUrl(to.path, store.state.locale, urlLocale)
    router.push({ path: newUrl, query: to.query, params: to.params })
    return
  }
  if (isTrailingSlashForUrlNeeded(to.path)) {
    router.push({ path: to.path + '/', query: to.query, params: to.params })
    return
  }
  next()
})

router.changeLocale = (newLocale) => {
  const url = window.location.pathname
  let currentUrlLocale = url.replace(/^\/([^\/]+).*/i, '$1')

  if (ALL_LOCALES.indexOf(currentUrlLocale) === -1) {
    currentUrlLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
  }

  if (newLocale !== currentUrlLocale) {
    const newUrl = addLocalePrefixForUrl(url, newLocale, currentUrlLocale)
    router.push({ path: newUrl, query: router.currentRoute.query, params: router.currentRoute.params })
  }
}

function addLocalePrefixForUrl (url, newLocale, currentUrlLocale) {
  let newUrl = ''
  if (newLocale === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
    newUrl = url.replace(`/${currentUrlLocale}`, '')
  } else if (currentUrlLocale === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
    newUrl = `/${newLocale}${url}`
  } else {
    newUrl = url.replace(`/${currentUrlLocale}`, `/${newLocale}`)
  }
  if (isTrailingSlashForUrlNeeded(newUrl)) {
    newUrl += '/'
  }
  return newUrl
}

function isTrailingSlashForUrlNeeded (url) {
  const matchLocales = ALL_LOCALES.filter(locale => locale !== process.env.VUE_APP_I18N_DEFAULT_LOCALE).join('|')
  return url.match(`^/(${matchLocales})$`)
}

function updatePreferredLanguage () {
  const preferredLanguage = Cookies.getCookieValue('Preferred-Language');
  if (!preferredLanguage || preferredLanguage !== store.state.locale) {
    document.cookie = `Preferred-Language=${store.state.locale}; path=/; domain=${process.env.VUE_APP_SOLUTION_COOKIE_DOMAIN}`
  }
}

export default router
