<template>
  <div class="ms-otp-registration">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-otp-registration-title-wrapper">
      <div class="ms-otp-form-title">{{ $t('auth.patientRegistration.page.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.patientRegistration.page.description') }}</div>
    </div>
    <form @submit.prevent="submitRegistrationForm" id="registrationStepForm">
      <div class="ms-otp-form-content-wrapper">
        <div class="ms-otp-form-content-left">
          <div class="ms-otp-form-content-title">1. {{ $t('auth.patientRegistration.step1') }}</div>
          <div v-bind:class="[firstNameFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <input class="ms-otp-input" type="text" name="firstName" ref="firstNameInput"
                   :placeholder="$t('auth.patientRegistration.placeholder.firstName')"
                   v-model="firstName" @focus="firstNameFieldErrorMessage = null"/>
            <span v-if="firstNameFieldErrorMessage" class="ms-otp-error-text">{{ firstNameFieldErrorMessage }}</span>
          </div>
          <div v-bind:class="[lastNameFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <input class="ms-otp-input" type="text" name="lastName"
                   :placeholder="$t('auth.patientRegistration.placeholder.lastName')"
                   v-model="lastName" @focus="lastNameFieldErrorMessage = null">
            <span v-if="lastNameFieldErrorMessage" class="ms-otp-error-text">{{ lastNameFieldErrorMessage }}</span>
          </div>
          <div v-bind:class="[emailFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <input class="ms-otp-input" type="email" name="email"
                   :placeholder="$t('auth.patientRegistration.placeholder.email')"
                   v-model="email" @focus="emailFieldErrorMessage = null" disabled>
            <span v-if="emailFieldErrorMessage" class="ms-otp-error-text">{{ emailFieldErrorMessage }}</span>
          </div>
          <span class="ms-otp-checkbox-wrap">
            <input type="checkbox" class="ms-otp-checkbox" id="show-password" v-model="checked"/>
            <label for="show-password" class="ms-otp-check-label">
              {{ $t('auth.patientRegistration.setPassword.title') }}
            </label>
            <span @click="toggleShowInformation"
                  :class="{ active: showInformation }"
                  class="ms-otp-tooltip-pw">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50" fill="#0DD8CE">
                <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
                </svg>
                <span class="ms-tooltip-text">{{ $t('auth.patientRegistration.setPassword.tooltip') }}</span>
            </span>
          </span>
          <div v-if="checked">
            <div v-bind:class="[passwordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
              <div class="ms-otp-input-password-wrap">
                <input v-if="showPassword" class="ms-otp-input" type="text" name="password"
                       :placeholder="$t('auth.patientRegistration.placeholder.password')"
                       v-model="password" @focus="passwordFieldErrorMessage = null" autocomplete="new-password">
                <input v-else type="password" class="ms-otp-input" :placeholder="$t('auth.login.password.placeholder')"
                       v-model="password" @focus="passwordFieldErrorMessage = null" autocomplete="new-password">
                <div :class="{ 'ms-eye': showPassword, 'ms-eye-slash': !showPassword }" class="ms-show-password-btn"
                     @click="togglePasswordShow">
                  <icon-eye-slashed/>
                  <icon-eye/>
                </div>
              </div>
              <span v-if="passwordFieldErrorMessage" class="ms-otp-error-text">{{ passwordFieldErrorMessage }}</span>
            </div>
            <div v-bind:class="[confirmPasswordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
              <div class="ms-otp-input-password-wrap">
                <input v-if="showConfirmPassword" class="ms-otp-input" type="text" name="password"
                       :placeholder="$t('auth.patientRegistration.placeholder.confirmPassword')"
                       v-model="confirmPassword" @focus="confirmPasswordFieldErrorMessage = null" autocomplete="new-password">
                <input v-else type="password" class="ms-otp-input" :placeholder="$t('auth.login.confirmPassword.placeholder')"
                       v-model="confirmPassword" @focus="confirmPasswordFieldErrorMessage = null" autocomplete="new-password">
                <div :class="{ 'ms-eye': showConfirmPassword, 'ms-eye-slash': !showConfirmPassword }" class="ms-show-password-btn"
                     @click="toggleConfirmPasswordShow">
                  <icon-eye-slashed/>
                  <icon-eye/>
                </div>
              </div>
              <span v-if="confirmPasswordFieldErrorMessage" class="ms-otp-error-text">{{ confirmPasswordFieldErrorMessage }}</span>
            </div>
          </div>
      </div>
        <div class="ms-otp-form-content-right">
          <div class="ms-otp-form-content-title">2. {{ $t('auth.patientRegistration.step2') }}</div>
          <span v-bind:class="[privacyFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
            <input type="checkbox" class="ms-otp-checkbox" id="privacy" name="privacy" v-model="privacy"
                   @change="privacyFieldErrorMessage = privacy">
            <label class="ms-otp-check-label" for="privacy"
                   v-html="$t('auth.patientRegistration.dataPrivacy.label', [localizeAgreementUrl('/content/legal')])"></label>
          </span>
          <div class="ms-otp-register-agree-text" v-html="privacyPolicy"></div>
          <span v-bind:class="[agbFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
            <input type="checkbox" class="ms-otp-checkbox" id="agb" name="agb" v-model="agb"
                   @change="agbFieldErrorMessage = agb">
            <label class="ms-otp-check-label" for="agb"
                   v-html="$t('auth.patientRegistration.agb.label', [localizeAgreementUrl('/content/userAgreement')])"></label>
          </span>
          <div class="ms-otp-registration-buttons">
            <div class="ms-otp-btn-wrap">
              <input type="submit" class="ms-otp-btn" :value="$t('default.register.title')"/>
            </div>
            <div class="ms-link-navigation">
              <router-link :to="{ path: localizeUrl('', '/') }" class="ms-back-link">
                {{ $t('default.back.title') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import IconEyeSlashed from '@/components/icons/IconEyeSlashed.vue'
import IconEye from '@/components/icons/IconEye.vue'
import UrlManager from '@/util/UrlManager'
import {LoginOption} from "@/constants/LoginOption";
import {mixinLoader} from "@/mixins/mixin-loader";
import {signinFlowApi} from "@/api/SigninFlowApi";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";
import {SigninProcessHeader} from "@/constants/SigninProcessHeader";

export default {
  name: 'Registration',
  mixins: [mixinLoader],
  components: {
    IconEye,
    IconEyeSlashed
  },
  metaInfo () {
    return {
      title: `${this.$t('auth.patientRegistration.label.title')} - Medicosearch`
    }
  },
  data () {
    return {
      errors: [],
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      privacy: false,
      agb: false,
      checked: false,
      showPassword: false,
      showConfirmPassword: false,
      showInformation: false,
      privacyPolicy: ''
    }
  },
  watch: {
    currentLocale (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showSpinner()
        this.fetchPrivacyPolicy()
          .then(() => this.hideSpinner())
      }
    }
  },
  computed: {
    solutionHost () {
      return process.env.VUE_APP_SOLUTION_HOST
    },
    signinProcessHeaders () {
      const headers = {}
      if (this.$route.query?.signinProcessToken) {
        headers[SigninProcessHeader.SIGNIN_PROCESS_TOKEN] = this.$route.query.signinProcessToken
      }
      return headers
    },
    firstNameFieldErrorMessage: {
      get () {
        return this.getFieldError('firstName')
      },
      set () {
        const locationError = this.getLocationError('firstName')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    lastNameFieldErrorMessage: {
      get () {
        return this.getFieldError('lastName')
      },
      set () {
        const locationError = this.getLocationError('lastName')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    emailFieldErrorMessage: {
      get () {
        return this.getFieldError('email')
      },
      set () {
        const locationError = this.getLocationError('email')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    passwordFieldErrorMessage: {
      get () {
        return this.getFieldError('password')
      },
      set () {
        const locationError = this.getLocationError('password')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    confirmPasswordFieldErrorMessage: {
      get () {
        return this.getFieldError('confirmPassword')
      },
      set () {
        const locationError = this.getLocationError('confirmPassword')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    privacyFieldErrorMessage: {
      get () {
        return this.getFieldError('privacy')
      },
      set(val) {
        if (!val) return
        const locationError = this.getLocationError('privacy')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    agbFieldErrorMessage: {
      get () {
        return this.getFieldError('agb')
      },
      set(val) {
        if (!val) return
        const locationError = this.getLocationError('agb')

        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    currentLocale () {
      return this.$store.state.locale
    }
  },
  created () {
    this.showSpinner()
    Promise.all([
      this.loadStep(),
      this.fetchPrivacyPolicy()
    ])
      .finally(() => this.hideSpinner())

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.LOGIN,
      ]
    )
  },
  mounted() {
    if (this.$refs.firstNameInput) {
      this.$refs.firstNameInput.focus();
    }
  },
  methods: {
    loadStep () {
      const params = {
        signinProcessStep: SigninProcessStep.REGISTRATION
      }
      return signinFlowApi.loadStep(params, this.signinProcessHeaders)
        .then((response) => {
          this.email = response.data.email
          this.firstName = response.data.firstName
          this.lastName = response.data.lastName
        })
        .catch((error) => {
          this.$router.push({ path: '/' })
        })
    },
    submitRegistrationForm () {
      const loginOption = this.checked ? LoginOption.PASSWORD : LoginOption.OTP
      const password = loginOption === LoginOption.PASSWORD ? this.password : null
      const confirmPassword = loginOption === LoginOption.PASSWORD ? this.confirmPassword : null
      const params = {
        firstName: this.firstName,
        lastName: this.lastName,
        password: password,
        confirmPassword: confirmPassword,
        privacy: this.privacy,
        agb: this.agb,
        loginOption: loginOption,
      }

      this.showSpinner()
      signinFlowApi.completeRegistration(params, this.signinProcessHeaders)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.hideSpinner()
        })
    },
    fetchPrivacyPolicy () {
      return signinFlowApi
        .fetchPrivacyPolicy()
        .then((response) => this.privacyPolicy = response.data.object.item.localizedPrivacyPolicy)
    },
    getLocationError (field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError (field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el, index, array) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    localizeAgreementUrl(path) {
      let currentUrl = new URL(window.location)

      if (currentUrl.searchParams.get('displayType') === 'embed') {
        path = '/embed' + path
      }
      return UrlManager.localizeUrl(this.solutionHost, path)
    },
    localizeUrl (host, path) {
      return UrlManager.localizeUrl(host, path)
    },
    togglePasswordShow () {
      this.showPassword = !this.showPassword
    },
    toggleConfirmPasswordShow () {
      this.showConfirmPassword = !this.showConfirmPassword
    },
    toggleShowInformation () {
      this.showInformation = !this.showInformation
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/helpers/all-helpers';

.ms-otp-registration-title-wrapper {
  .ms-otp-form-title {
    text-align: center;
    @include md-up {
      text-align: start;
    }
  }

  .ms-otp-caption {
    text-align: center;
    margin: 0 0 34px;
    @include md-up {
      margin: 0 0 58px;
      text-align: start;
    }
  }
}

.ms-otp-caption:first-child {
  margin: 0;
}

.ms-otp-form-content-wrapper {
  @include display-flex;
  @include flex-direction-column;
  @include ltm-up {
    @include flex-direction-row;
    @include justify-content-space-between;
  }

  .ms-otp-form-content {
    &-left {
      width: 100%;
      @include ltm-up {
        width: 41%;
      }
    }
    &-right {
      width: 100%;
      @include ltm-up {
        width: 56%;
      }
    }
  }
}

.ms-otp-form-row {
  margin-bottom: 16px;
}

.ms-otp-form-content-title {
  font-family: $montserrat;
  font-weight: 600;
  font-size: 14px;
  color: $black-text;
  margin-bottom: 21px;
}

.ms-otp-register-agree-text::v-deep {
  max-height: 125px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #FFFFFF;
  border: 1px solid #00D0C8;
  border-radius: 6px;
  padding: 16px 22px 22px;
  font-family: $montserrat;
  font-weight: 500;
  font-size: 12px;
  line-height: 165%;
  color: $dark-gray-text;
  margin: 21px 0;

  b {
    font-size: 14px;
  }

  a {
    color: $turquoise-bg;
  }
  .msr-table-wrap{
    max-width: 100%;
    overflow: auto;
  }
  .msr-table-flex-table {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: 100%;
    max-width: 100%;
    min-width: 600px;
    margin: 20px auto;
  }
  .msr-table-flex-row {
    display: flex;
    border-top: 1px solid;
    &:first-child{
      border-top: none;
    }
  }
  .msr-table-header {
    font-family: $montserrat;
    font-weight: 500;
  }
  .msr-table-flex-cell {
    padding: 10px;
    border-right: 1px solid;
  }
  .msr-table-flex-cell:first-child {
    flex: 1;
    width: 30%;
    @media (max-width: 991px) {
      word-break: break-all;
    }
  }
  .msr-table-flex-cell:nth-child(2){
    width: 30%;
  }
  .msr-table-flex-cell:nth-child(3) {
    width: 40%;
    word-break: break-all;
  }
  .msr-table-flex-cell:last-child {
    border-right: none;
  }
  .ms-otp-register-table {
    max-width: 100%;
    overflow: auto;
  }
}
.ms-otp-checkbox-wrap {
  position: relative;
}
.ms-otp-tooltip-pw {
  position: absolute;
  right: -30px;
  &.active {
    .ms-tooltip-text {
      display: block;
      opacity: 1;
      visibility: visible;
      @include lg-up {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  @include lg-up {
  &:hover {
    .ms-tooltip-text {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
  }
  .ms-tooltip-text {
    position: absolute;
    top: 30px;
    left: unset;
    right: -44px;
    display: none;
    width: 300px;
    background-color: #FFFFFF;
    padding: 4px 6px;
    font-family: $montserrat;
    font-weight: 400;
    font-size: 10px;
    opacity: 0;
    visibility: hidden;
    @include box-shadow(0px 2px 10px rgba(0, 0, 0, 0.2));
    @include border-radius(6px);
    @include transition(.3s);
    z-index: 2;
    @include ltm-up {
      left: -6px;
    }
    &:before {
      content: " ";
      position: absolute;
      top: -12px;
      left: unset;
      right: 48px;
      border-top: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #FFFFFF;
      @include ltm-up {
        left: 10px;
      }
    }
  }
}
</style>
