<template>
  <div class="ms-otp-registration">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-otp-registration-title-wrapper">
      <img src="../../../assets/images/otp-agreement-update-booking.png" alt="image">
      <div class="ms-otp-form-title">{{ $t('auth.acceptAgreement.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.acceptAgreement.subtitle') }}</div>
    </div>

    <div class="ms-otp-form-content-wrapper">
      <div class="ms-otp-form-content-right">
        <span v-bind:class="[privacyFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
          <input type="checkbox" class="ms-otp-checkbox" id="privacy" name="privacy" v-model="privacy"
                 @change="privacyFieldErrorMessage = privacy">
          <label class="ms-otp-check-label" for="privacy"
                 v-html="$t('auth.patientRegistration.dataPrivacy.label', [localizeAgreementUrl('/content/legal')])"></label>
        </span>
        <div class="ms-otp-register-agree-text" v-html="privacyPolicy"></div>
        <span v-bind:class="[agbFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
          <input type="checkbox" class="ms-otp-checkbox" id="agb" name="agb" v-model="agb"
                 @change="agbFieldErrorMessage = agb">
          <label class="ms-otp-check-label" for="agb"
                 v-html="$t('auth.patientRegistration.agb.label', [localizeAgreementUrl('/content/userAgreement')])"></label>
        </span>

        <div class="ms-otp-btn-wrap">
          <button @click="declineUpdateAgreement" class="ms-otp-btn ms-otp-btn-outline">{{ $t('auth.acceptAgreement.button.decline') }}</button>
          <button @click="submitUpdateAgreement" class="ms-otp-btn">{{ $t('auth.acceptAgreement.button.accept') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixinLoader} from "@/mixins/mixin-loader";
import IconEye from "@/components/icons/IconEye.vue";
import IconEyeSlashed from "@/components/icons/IconEyeSlashed.vue";
import UrlManager from "@/util/UrlManager";
import {signinFlowApi} from "@/api/SigninFlowApi";
import {SigninProcessService} from "@/service/SigninProcessService";
import {SigninProcessStep} from "@/constants/SigninProcessStep";

export default {
  name: 'UpdateAgreement',
  mixins: [mixinLoader],
  components: {
    IconEye,
    IconEyeSlashed
  },
  metaInfo () {
    return {
      title: this.$t('auth.acceptAgreement.title')
    }
  },
  data () {
    return {
      errors: [],
      email: '',
      agb: false,
      privacy: false,
      privacyPolicy: ''
    }
  },
  watch: {
    currentLocale (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showSpinner()
        this.fetchPrivacyPolicy()
          .then(() => this.hideSpinner())
      }
    }
  },
  computed: {
    solutionHost () {
      return process.env.VUE_APP_SOLUTION_HOST
    },
    privacyFieldErrorMessage: {
      get () {
        return this.getFieldError('privacy')
      },
      set (val) {
        if (!val) return
        const locationError = this.getLocationError('privacy')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    agbFieldErrorMessage: {
      get () {
        return this.getFieldError('agb')
      },
      set (val) {
        if (!val) return
        const locationError = this.getLocationError('agb')

        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    currentLocale () {
      return this.$store.state.locale
    }
  },
  created () {
    this.showSpinner()

    Promise.all([
      this.loadStep(),
      this.fetchPrivacyPolicy()
    ])
      .finally(() => this.hideSpinner())

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.LOGIN,
      ]
    )
  },
  methods: {
    loadStep () {
      return signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.UPDATE_AGREEMENT})
        .then((response) => {
          this.email = response.data.email
        })
        .catch((error) => {
          this.$router.push({ path: '/' })
        })
    },
    fetchPrivacyPolicy () {
      return signinFlowApi
        .fetchPrivacyPolicy()
        .then((response) => this.privacyPolicy = response.data.object.item.localizedPrivacyPolicy)
    },
    submitUpdateAgreement () {
      const params = {
        agb: this.agb,
        privacy: this.privacy,
      }

      this.showSpinner()
      signinFlowApi.updateAgreement(params)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.hideSpinner()
        })
    },
    declineUpdateAgreement () {
      this.$router.push("/")
    },
    getLocationError (field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError (field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el, index, array) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    localizeAgreementUrl(path) {
      let currentUrl = new URL(window.location)

      if (currentUrl.searchParams.get('displayType') === 'embed') {
        path = '/embed' + path
      }
      return UrlManager.localizeUrl(this.solutionHost, path)
    },
    localizeUrl (host, path) {
      return UrlManager.localizeUrl(host, path)
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/helpers/all-helpers';

.ms-otp-registration-title-wrapper {
  position: relative;
  img {
    display: none;
  }
  @include md-up {
    padding-left: 122px;
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 90px;
      height: 90px;
    }
  }
  .ms-otp-form-title {
    text-align: center;
    font-size: 20px;
    @include md-up {
      text-align: start;
    }
    @include sm-up {
      font-size: 25px;
    }
  }
  .ms-otp-caption {
    max-width: 760px;
    text-align: center;
    margin: 0 0 34px;
    @include md-up {
      margin: 0 0 58px;
      text-align: start;
    }
  }
}
.ms-otp-caption:first-child {
  margin: 0;
}
.ms-otp-form-content-wrapper {
  @include display-flex;
  @include flex-direction-column;
  @include ltm-up {
    @include flex-direction-row;
    @include justify-content-space-between;
  }
  .ms-otp-form-content {
    &-right {
      width: 100%;
      max-width: 800px;
      @include md-up {
        margin-left: 122px;
      }
    }
  }
}
.ms-otp-btn-wrap {
  text-align: center;
  @include md-up {
    text-align: start;
  }
}
.ms-otp-form-row {
  margin-bottom: 16px;
}
.ms-otp-form-content-title {
  font-family: $montserrat;
  font-weight: 600;
  font-size: 14px;
  color: $black-text;
  margin-bottom: 21px;
}
.ms-otp-register-agree-text::v-deep {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #FFFFFF;
  border: 1px solid #00D0C8;
  border-radius: 6px;
  padding: 16px 22px 22px;
  font-family: $montserrat;
  font-weight: 500;
  font-size: 12px;
  line-height: 165%;
  color: $dark-gray-text;
  margin: 0 auto 21px;
  b {
    font-size: 14px;
  }
  a {
    color: $turquoise-bg;
  }
  .msr-table-wrap{
    max-width: 100%;
    overflow: auto;
  }
  .msr-table-flex-table {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: 100%;
    max-width: 100%;
    min-width: 600px;
    margin: 20px auto;
  }
  .msr-table-flex-row {
    display: flex;
    border-top: 1px solid;
    &:first-child{
      border-top: none;
    }
  }
  .msr-table-header {
    font-family: $montserrat;
    font-weight: 500;
  }
  .msr-table-flex-cell {
    padding: 10px;
    border-right: 1px solid;
  }
  .msr-table-flex-cell:first-child {
    flex: 1;
    width: 30%;
    @media (max-width: 991px) {
      word-break: break-all;
    }
  }
  .msr-table-flex-cell:nth-child(2){
    width: 30%;
  }
  .msr-table-flex-cell:nth-child(3) {
    width: 40%;
    word-break: break-all;
  }
  .msr-table-flex-cell:last-child {
    border-right: none;
  }
  .ms-otp-register-table {
    max-width: 100%;
    overflow: auto;
  }
}
.ms-otp-checkbox-wrap{
  height: auto;
}
</style>
