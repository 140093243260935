<template>
  <div class="ms-container">
    <div class="ms-error-page">
      <div class="ms-error-page-404">
        <div class="ms-error-pos">
          <div class="ms-error-page-title">{{ $t('errorPage.404.title') }}</div>
          <div class="ms-error-page-info">{{ $t('errorPage.404.content') }}</div>
        </div>
        <div class="ms-error-img">
          <img src="@/assets/images/error-pages/error404@2x.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  metaInfo () {
    return {
      title: this.$t('errorPage.404.pageTitle')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/helpers/all-helpers";

  .ms-error-page {
    padding: 60px 0 160px;
    width: 100%;
    @include mq(tablet-wide, max) {
      padding: 35px 0;
    }
  }

  .ms-error-page-404 {
    position: relative;
    @include display-flex;
    @include align-items-center;
    min-height: 388px;

    .ms-error-pos {
      position: relative;
      @include flex-grow(1);
      padding: 0 20px 0 0;
      z-index: 1;
    }

    .ms-error-img {
      width: 46%;

      img {
        height: auto;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
      }
    }

    @include mq(tablet-wide, max) {
      @include flex-direction(column);
      .ms-error-pos {
        padding: 0 0 50px;
      }
      .ms-error-img {
        width: 100%;
        text-align: center;

        img {
          max-width: 270px;
        }
      }
    }
    @include mq(tablet, max) {
      .ms-error-img {
        img {
          max-width: 200px;
        }
      }
    }
  }

  .ms-error-page-title {
    position: relative;
    font-family: $montserrat;
    font-weight: 700;
    font-size: 60px;
    line-height: 1.2;
    color: $black-text;
    padding: 0 0 17px;
    margin: 0 0 20px;

    &:before {
      @include pseudo();
      bottom: 0;
      left: 0;
      height: 1px;
      width: 80px;
      background: rgba($turquoise-bg, 0.3);
    }

    @include mq(desktop, max) {
      font-size: 50px;
    }
    @include mq(tablet-wide, max) {
      font-size: 40px;
    }
  }

  .ms-error-page-info {
    font-family: $montserrat;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.5;
    width: 50%;
    @include mq(tablet-wide, max) {
      width: 100%;
    }
  }
</style>
