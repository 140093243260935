<template>
  <div class="ms-otp-one-col">
    <div class="ms-otp-form-title">{{ $t('auth.enableTwoFactorAuthQrCode.title') }}</div>
    <div class="ms-otp-caption">{{ $t('auth.enableTwoFactorAuthQrCode.description') }}</div>
    <div class="ms-otp-qr">
      <img src="@/assets/images/download-app-qr.webp" alt="">
    </div>
    <div class="ms-app-links" data-v-e3fa23b9="">
      <a href="https://apps.apple.com/ch/app/medicosearch/id1153385466?ls=1" target="_blank" aria-label="Download on the App Store" class="ms-app-link ms-app-store">
      <svg width="33" height="40" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7763 4.15539C19.5142 2.07814 22.1211 0.831787 24.2934 0.831787C24.7279 3.32449 23.859 5.8172 22.5555 7.89445C20.8176 9.55625 18.6453 10.8026 16.4729 10.8026H16.0384C15.6039 10.8026 15.6039 10.3872 15.6039 10.3872C15.1694 7.89445 16.4729 5.40174 17.7763 4.15539ZM4.5609 34.8673C0.974134 29.6124 -1.01852 20.7195 2.16972 15.0604C4.16237 11.8266 7.35061 10.2097 10.5388 9.80552C11.6683 9.80552 12.5977 10.2113 13.4687 10.5917C13.8271 10.7482 14.1756 10.9004 14.5241 11.0182C14.9808 11.1726 15.3793 11.327 15.7197 11.4589C16.2705 11.6722 16.669 11.8266 16.9153 11.8266C17.3139 11.8266 18.1109 11.4224 18.908 11.0182C19.1902 10.9228 19.4947 10.8048 19.8161 10.6803C20.8562 10.2773 22.074 9.80552 23.2918 9.80552H24.0889C25.2844 9.80552 28.8712 10.614 31.6609 13.4435V13.8477V14.252C30.8639 14.6562 27.6756 16.6773 27.6756 21.1237C27.6756 25.9501 30.8642 27.8364 32.0498 28.5377C32.2544 28.6587 32.3993 28.7445 32.458 28.804V29.2082C32.3583 29.4103 32.2338 29.7135 32.0781 30.0924C31.6111 31.2293 30.8639 33.0483 29.6683 34.8673C28.0742 37.2926 26.48 39.718 23.6903 39.718C22.0962 39.718 21.2991 39.3137 20.5021 38.9095C19.705 38.5053 18.908 38.1011 17.3139 38.1011C16.1469 38.1011 15.407 38.3177 14.469 38.5924C14.1256 38.6929 13.7557 38.8012 13.3286 38.9095C12.5315 39.3137 11.7344 39.718 10.5388 39.718C8.14767 39.718 6.55355 37.6969 4.5609 34.8673Z" fill="white"></path>
      </svg>
      <div class="ms-app-link-text">
        <span class="ms-app-caption">{{ $t('auth.enableTwoFactorAuthQrCode.downloadOnThe') }}</span>
        <span class="ms-app-name">{{ $t('auth.enableTwoFactorAuthQrCode.appStore') }}</span>
      </div>
    </a>
      <a href="https://play.google.com/store/apps/details?id=com.medicosearch.app" target="_blank" aria-label="Get it on Google Play" class="ms-app-link ms-google-play">
      <svg width="33" height="35" viewBox="0 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_560_4029)">
          <path
            d="M31.707 16.9889L25.3299 13.3141L20.0781 18.0913L24.9548 22.8685L31.707 18.8262C32.0822 18.4588 32.4573 18.0913 32.4573 17.7238C32.4573 17.3563 32.0822 16.9889 31.707 16.9889Z"
            fill="#FFC100" data-v-8e1a474a=""></path>
          <path d="M24.2028 12.5793L17.0754 8.53709L1.69519 0.820068L19.3261 17.3565L24.2028 12.5793Z" fill="#00F076"
                data-v-8e1a474a=""></path>
          <path d="M1.69531 35.3621L17.0755 27.2776L23.8277 23.6028L19.3262 19.1931L1.69531 35.3621Z" fill="#FF3A44"
                data-v-8e1a474a=""></path>
          <path d="M0.570312 1.55444V34.9949L18.2012 18.0909L0.570312 1.55444Z" fill="#00D3FF"
                data-v-8e1a474a=""></path>
        </g>
        <defs data-v-8e1a474a="">
          <clipPath id="clip0_560_4029" data-v-8e1a474a="">
            <rect width="31.8857" height="34.5655" fill="white" transform="translate(0.570312 0.265381)"
                  data-v-8e1a474a=""></rect>
          </clipPath>
        </defs>
      </svg>
      <div class="ms-app-link-text">
        <span class="ms-app-caption">{{ $t('auth.enableTwoFactorAuthQrCode.getItOn') }}</span>
        <span class="ms-app-name">{{ $t('auth.enableTwoFactorAuthQrCode.googlePlay') }}</span></div>
    </a></div>
  </div>
</template>

<script>

export default {
  name: 'Enable2FAQR',
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/helpers/all-helpers';

.ms-otp-qr {
  background: #fff;
  border: 1px solid #a0e8b8;
  height: 175px;
  padding: 11px;
  width: 175px;
  border-radius: 12px;
  margin: 0 auto 25px;

  img {
    width: 100%;
  }
}
.ms-app-links {
  @include display-flex;
  @include flex-direction-column;
  @include align-items-center;
  @include justify-content-center;
  @include md-up {
    @include align-items-flex-start;
    @include flex-direction-row;
  }
  svg{
    height: 30px;
    width: 22px;
  }
}

.ms-app-link {
  @include display-flex;
  @include align-items-center;
  position: relative;
  padding-left: 20px;
  width: 200px;
  height: 47px;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
  @include border-radius(12px);
  cursor: pointer;
  @include transition(0.5s);

  &:first-child {
    margin-bottom: 28px;
  }

  @include sm-up {
    width: 163px;
    height: 39px;
    @include border-radius(8px);
    &:first-child {
      margin-bottom: 13px;
    }
  }
  @include md-up {
    width: 192px;
    height: 55px;
    padding-left: 20px;
    @include border-radius(12px);
    &:first-child {
      margin-right: 13px;
    }
    &:hover {
      @include box-shadow(4px 10px 45px rgba(0, 0, 0, 0.05));
    }
  }

  .ms-app-link-text {
    @include display-flex;
    @include flex-direction-column;
    cursor: pointer;
    margin-left: 17px;
    @include sm-up {
      margin-left: 12px;
    }
    @include md-up {
      margin-left: 20px;
    }
  }

  .ms-app-caption {
    font-family: $inter;
    font-size: 10px;
    line-height: 110%;
  }

  .ms-app-name {
    font-family: $inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }
}
</style>
